import React from "react";

import PNG_One_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@2x.png";
import PNG_One_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@1200_2x.png";
import PNG_One_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@992_2x.png";
import PNG_One_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@768_2x.png";
import PNG_One_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@mob_2x.png";

import WEBP_One_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@2x.webp";
import WEBP_One_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@1200_2x.webp";
import WEBP_One_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@992_2x.webp";
import WEBP_One_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@768_2x.webp";
import WEBP_One_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_1@mob_2x.webp";

import PNG_Two_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@2x.png";
import PNG_Two_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@1200_2x.png";
import PNG_Two_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@992_2x.png";
import PNG_Two_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@768_2x.png";
import PNG_Two_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@mob_2x.png";

import WEBP_Two_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@2x.webp";
import WEBP_Two_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@1200_2x.webp";
import WEBP_Two_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@992_2x.webp";
import WEBP_Two_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@768_2x.webp";
import WEBP_Two_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_2@mob_2x.webp";

import PNG_Three_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@2x.png";
import PNG_Three_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@1200_2x.png";
import PNG_Three_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@992_2x.png";
import PNG_Three_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@768_2x.png";
import PNG_Three_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@mob_2x.png";

import WEBP_Three_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@2x.webp";
import WEBP_Three_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@1200_2x.webp";
import WEBP_Three_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@992_2x.webp";
import WEBP_Three_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@768_2x.webp";
import WEBP_Three_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_3@mob_2x.webp";

import PNG_Four_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@2x.png";
import PNG_Four_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@1200_2x.png";
import PNG_Four_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@992_2x.png";
import PNG_Four_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@768_2x.png";
import PNG_Four_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@mob_2x.png";

import WEBP_Four_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@2x.webp";
import WEBP_Four_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@1200_2x.webp";
import WEBP_Four_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@992_2x.webp";
import WEBP_Four_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@768_2x.webp";
import WEBP_Four_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_4@mob_2x.webp";

import PNG_Five_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@2x.png";
import PNG_Five_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@1200_2x.png";
import PNG_Five_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@992_2x.png";
import PNG_Five_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@768_2x.png";
import PNG_Five_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@mob_2x.png";

import WEBP_Five_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@2x.webp";
import WEBP_Five_1200_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@1200_2x.webp";
import WEBP_Five_992_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@992_2x.webp";
import WEBP_Five_768_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@768_2x.webp";
import WEBP_Five_mob_2x from "../../../assets/images/raster/pages/amazon-toolkit/Tools/tools_5@mob_2x.webp";

import SVG_IC_GO from "../../../assets/images/vector/pages/amazon-toolkit/ic_go_orange.svg";
import TrackedLink from "../../Analytics/TrackedLink";

const Link = ({ text, path }) => (
  <TrackedLink
    classes="PgAT-T-I-link"
    category="LANDING|amazon-toolkit"
    action={text}
    path={path}
  >
    {text}
    <img src={SVG_IC_GO} alt="" />
  </TrackedLink>
);

const LINK_PROPS = {
  STEP_BY_STEP_COURSE: {
    text: "Step-by-Step Course",
    path: "/amazon-seller-training-courses?r=lp_bundle_oa_t",
  },
  SIX_AND_SEVEN_FIGURE_SELLERS_MASTERCLASSES: {
    text: "Six- and Seven-figure Sellers Masterclasses",
    path: "/free-amazon-fba-masterclasses?r=lp_bundle_oa_t",
  },
  AMAZON_DROPSHIPPING_ARBITRAGE_AND_WHOLESALE_TOOL: {
    text: "Amazon Dropshipping, Arbitrage, and Wholesale tool",
    path: "https://chromewebstore.google.com/detail/amazon-dropshipping-arbit/edkgpjhfpfpgkohafpaliolcfnijnibh?utm_source=site&utm_medium=amzscout&utm_campaign=lp_bundle_oa_t",
  },
  PRO_EXTENSION: {
    text: "PRO Extension",
    path: "https://chromewebstore.google.com/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea?utm_source=site&utm_medium=amzscout&utm_campaign=lp_bundle_oa_t",
  },
  PRODUCT_DATABASE: {
    text: "Product Database",
    path: "/app/#/database?r=lp_bundle_oa_t",
  },
  STOCK_STATS: {
    text: "Stock Stats",
    path: "https://chromewebstore.google.com/detail/amzscout-stock-stats-amaz/liobflkelkokkacdemhmgkbpefgaekkm?utm_source=site&utm_medium=amzscout&utm_campaign=lp_bundle_oa_t",
  },
  REVERSE_ASIN_LOOKUP: {
    text: "Reverse ASIN Lookup",
    path: "/app/#/product-keywords?r=lp_bundle_oa_t",
  },
  AMAZON_KEYWORD_SEARCH: {
    text: "Amazon Keyword Search",
    path: "/app/#/keywords?r=lp_bundle_oa_t",
  },
  THOUSAND_AMAZON_PRODUCTS: {
    text: "1,000+ Amazon products",
    path: "/app/#/reports/insights?r=lp_bundle_oa_t",
  },
  MONTHLY_REPORTS: {
    text: "monthly reports",
    path: "/app/#/reports/reports?r=lp_bundle_oa_t",
  },
};


const TOOLS_CONTENT = [
  {
    title: "1. Learn simple tutorials to help you get a headstart on a low budget using dropshipping, online arbitrage, or wholesale",
    infoList: [
      {
        title: <Link {...LINK_PROPS.STEP_BY_STEP_COURSE} />,
        firstText: "Our step-by-step course teaches you exactly how to do the following:",
        list: [
          "Find the right products based on key criteria for different selling models",
          "Source suitable products to ensure that you save money and retain quality",
          "Learn how to get the Buy Box and sell organically",
          "Sell products effectively through PPC campaigns",
        ],
        afterListText:
          "Learn all of these things through 12 educational videos on the step-by-step course.",
      },
      {
        title: <><Link {...LINK_PROPS.SIX_AND_SEVEN_FIGURE_SELLERS_MASTERCLASSES} /> , led by accomplished sellers who have achieved success
          without their own brand</>,
        listParagraphs: [
          "Leverage the expertise of proven sellers to start your own seven-figure Amazon business. Learn from people who have made mistakes, so you don’t have to.",
          "We offer 34 masterclasses from proven seven-figure sellers who can teach you in-depth strategies to help you grow your business.",
        ],
      },
    ],
    screen: {
      PNG: PNG_One_2x,
      PNG_1200: PNG_One_1200_2x,
      PNG_992: PNG_One_992_2x,
      PNG_768: PNG_One_768_2x,
      PNG_mob: PNG_One_mob_2x,
      WEBP: WEBP_One_2x,
      WEBP_1200: WEBP_One_1200_2x,
      WEBP_992: WEBP_One_992_2x,
      WEBP_768: WEBP_One_768_2x,
      WEBP_mob: WEBP_One_mob_2x,
    },
  },
  {
    title: "2. Find high-profit products to resell in practice with all-in-one tools",
    infoList: [
      {
        title: <Link {...LINK_PROPS.AMAZON_DROPSHIPPING_ARBITRAGE_AND_WHOLESALE_TOOL} />,
        firstText: "Revive product information on any Amazon page about the following:",
        list: [
          "The Buy Box price and owner history",
          "The minimum price (Amazon, FBA, and FBM sellers)",
          "A breakdown of current fees",
          "Your potential return on investment (ROI)",
          "Hazmat information, and other indicators",
        ],
        afterListText:
          "Determine whether pursuing a product is worth your time. This tool enables you to select products with the highest potential profits.",
      },
      {
        title: <Link {...LINK_PROPS.PRO_EXTENSION} />,
        firstText: "Estimate the potential of lists of products based on their stats, such as:",
        list: [
          "Sales statistics",
          "Historical data",
          "A detailed overall product value for selling",
          "A system of scoring and tips that will help you choose the best products for you to sell",
        ],
        afterListText:
          "Over 500,000 users have used the AMZScout PRO Extension to find numerous profitable product targets.",
      },
    ],
    screen: {
      PNG: PNG_Two_2x,
      PNG_1200: PNG_Two_1200_2x,
      PNG_992: PNG_Two_992_2x,
      PNG_768: PNG_Two_768_2x,
      PNG_mob: PNG_Two_mob_2x,
      WEBP: WEBP_Two_2x,
      WEBP_1200: WEBP_Two_1200_2x,
      WEBP_992: WEBP_Two_992_2x,
      WEBP_768: WEBP_Two_768_2x,
      WEBP_mob: WEBP_Two_mob_2x,
    },
  },
  {
    title: "3. Evaluate potential profits and analyze inventory of competitors’ items",
    infoList: [
      {
        title: <Link {...LINK_PROPS.PRODUCT_DATABASE} />,
        firstText: "Filter through millions of products, saving you time figuring out which products will be the most profitable. You can use the filters, including the number of sellers, monthly sales, margin, fees, and more, to help you find the most suitable products.",
      },
      {
        title: <Link {...LINK_PROPS.STOCK_STATS} />,
        firstText: "See how much your competitors have in their inventory, as you enjoy the following benefits:",
        list: [
          "Take advantage of their inventory shortages to increase your sales",
          "See how successful competitors do it, and try to emulate their strategies",
          "Reach a higher profit margin by knowing how much you need to keep in order to fulfill Amazon’s requirements",
        ],
      },
    ],
    screen: {
      PNG: PNG_Three_2x,
      PNG_1200: PNG_Three_1200_2x,
      PNG_992: PNG_Three_992_2x,
      PNG_768: PNG_Three_768_2x,
      PNG_mob: PNG_Three_mob_2x,
      WEBP: WEBP_Three_2x,
      WEBP_1200: WEBP_Three_1200_2x,
      WEBP_992: WEBP_Three_992_2x,
      WEBP_768: WEBP_Three_768_2x,
      WEBP_mob: WEBP_Three_mob_2x,
    },
  },
  {
    title: "4. Stand out and promote your product on the Amazon search page",
    infoList: [
      {
        title: <Link {...LINK_PROPS.REVERSE_ASIN_LOOKUP} />,
        firstText: "Discover relevant keywords for a specific ASIN of an existing product:",
        list: [
          "Copy your competitors’ lists to use in your own PPC campaigns",
          "Understand buyers’ intents with greater clarity by seeing how competitors describe similar products for easy searching",
        ],
      },
      {
        title: <Link {...LINK_PROPS.AMAZON_KEYWORD_SEARCH} />,
        firstText: "Discover thousands of effective keywords for your product:",
        list: [
          "Increase your sales potential by adding multiple keywords with strategic placement of phrases and words for which you know the targets",
          "Use these targets to bring greater effectiveness to your PPC campaigns and increase your cost-effectiveness with proper buyer intent",
        ],
      },
    ],
    screen: {
      PNG: PNG_Four_2x,
      PNG_1200: PNG_Four_1200_2x,
      PNG_992: PNG_Four_992_2x,
      PNG_768: PNG_Four_768_2x,
      PNG_mob: PNG_Four_mob_2x,
      WEBP: WEBP_Four_2x,
      WEBP_1200: WEBP_Four_1200_2x,
      WEBP_992: WEBP_Four_992_2x,
      WEBP_768: WEBP_Four_768_2x,
      WEBP_mob: WEBP_Four_mob_2x,
    },
  },
  {
    title: "5. Use best-selling products with proven potential to launch",
    infoList: [
      {
        title: <><Link {...LINK_PROPS.THOUSAND_AMAZON_PRODUCTS} /> you can sell today</>,
        firstText: "Receive 19 high-demand, low-competition products each week that you can start selling today. Our proven AI toolset chooses from more than 600 million products available on Amazon.",
      },
      {
        title: <>Trending Amazon products released in <Link {...LINK_PROPS.MONTHLY_REPORTS} /></>,
        firstText: "Our reports use historical data to determine which products have long-term profitability. Find profitable niches, the latest Amazon news, eCommerce updates, and expert tips to boost your profits.",
      },
    ],
    screen: {
      PNG: PNG_Five_2x,
      PNG_1200: PNG_Five_1200_2x,
      PNG_992: PNG_Five_992_2x,
      PNG_768: PNG_Five_768_2x,
      PNG_mob: PNG_Five_mob_2x,
      WEBP: WEBP_Five_2x,
      WEBP_1200: WEBP_Five_1200_2x,
      WEBP_992: WEBP_Five_992_2x,
      WEBP_768: WEBP_Five_768_2x,
      WEBP_mob: WEBP_Five_mob_2x,
    },
  },
];
export default TOOLS_CONTENT;